@media (max-width: 1400px) {
	.menu-colum {
		width: 330px;
		padding: 40px; }

	.main-slider {
		&__content-wrap {
			width: 680px; }
		&__title {
			font-size: 35px; }
		&__item {
			padding-left: 80px; }
		&__prev {
			left: 340px; } }

	.info-menu {
		left: 417px; }

	.main-slider-wrap {
		&__btn {
			right: 15px; } }

	.gallery-block {
		&__description {
			padding: 30px; } }

	.gallery-block {
		&__next {
			right: 70px; }
		.slick-counter {
			right: 133px; }
		&__prev {
			left: inherit;
			right: 215px; } }

	.char-slider {
		&__prev {
			left: 190px; }
		&__next {
			right: 185px; } }

	.win-card {
		&__heading {
			font-size: 14px; }
		&__text {
			height: 110px; } }

	.form-block {
		display: block;
		&__input {
			display: block;
			margin-bottom: 15px;
			width: 100%;
			padding: 15px; }
		&__btn {
			width: 100%; } }

	.install-slider {
		&__text {
			height: 80px; }
		&__prev {
			left: -60px; } }

	.addition-card {
		padding: 35px;
		&__text {
			height: 100px; } }

	.point {
		font-size: 14px;
		&:before {
			width: 15px;
			height: 15px;
			border: 2px solid #d5d5d5;
			left: -21px;
			top: 2px; } }

	.point-info {
		top: -18px;
		left: 22px; }

	.spb {
		top: 179px;
		left: 206px; }

	.arkhangelsk {
		top: 215px;
		left: 321px; }

	.moscow {
		top: 268px;
		left: 177px; }

	.novgorod {
		top: 315px;
		left: 206px; }

	.semferopol {
		top: 325px;
		left: 41px; }

	.perm {
		top: 342px;
		left: 336px; }

	.rostov {
		top: 359px;
		left: 23px;
		&:before {
			left: 60px; }
		& .point-info {
			left: 109px; } }

	.kasan {
		top: 345px;
		left: 228px; }

	.volgograd {
		top: 376px;
		left: 132px; }

	.ufa {
		top: 376px;
		left: 270px; }

	.ecaterenburg {
		top: 366px;
		left: 346px; }

	.krasnodar {
		top: 391px;
		left: 79px; }

	.sochi {
		top: 415px;
		left: 80px; }

	.astrahan {
		top: 419px;
		left: 149px; }

	.orenburg {
		top: 410px;
		left: 254px; }

	.cheljabinsk {
		top: 393px;
		left: 320px; }

	.omsk {
		top: 439px;
		left: 430px; }

	.novosibirsk {
		top: 520px;
		left: 521px; }

	.krasnojarsk {
		top: 490px;
		right: 444px; }

	.irkutsk {
		bottom: 79px;
		right: 364px; }

	.chita {
		bottom: 106px;
		right: 263px; }

	.habarovsk {
		bottom: 114px;
		right: 60px;
		& .point-info {
			left: inherit;
			right: 130px; } }

	.vladivostok {
		bottom: 38px;
		right: 18px;
		& .point-info {
			left: inherit;
			right: 145px; } }

	.magadan {
		top: 266px;
		right: 60px;
		& .point-info {
			left: inherit;
			right: 120px; } }

	.footer-menu {
		&__link {
			font-size: 12px; } } }

@media (max-width: 1200px) {
	.remodal {
		max-width: 700px; }

	.remodal-content {
		&__image {
			width: 330px;
			img {
				width: 100%;
				height: auto; } }
		&__info {
			padding: 40px 30px;
			flex-shrink: inherit; }
		&__heading {
			text-align: left; }
		.char-list__item {
			width: 100%; } }

	.remodal-form {
		&__btn {
			width: 100% !important;
			padding: 15px 0 !important; } }

	.remodal-form {
		padding: 40px 30px; }

	.menu-colum {
		display: none; }

	.info-menu {
		display: none; }

	.main-slider-wrap {
		&__btn {
			display: none; } }

	.mobile-header {
		display: block; }

	.btn {
		padding: 8px 20px;
		width: auto; }

	.main-slider {
		&__item {
			padding: 120px 30px 170px 30px; } }

	.main-slider {
		&__content-wrap {
			padding: 0;
			margin: 0; }
		.slick-dots {
			left: 0;
			width: 100%;
			text-align: center;
			justify-content: center; } }

	.mobile-menu {
		display: block; }

	.gallery-block {
		&__list {
			justify-content: center;
			flex-wrap: wrap; }
		&__item {
			margin-right: 70px;
			margin-bottom: 15px; }
		&__switch {
			margin-bottom: 15px; } }

	.gallery-block {
		&__flex-wrap {
			display: block; }
		&__image {
			width: 100%;
			img {
				width: 100%; } }
		&__description {
			padding: 30px 30px 80px 30px; }
		.slick-counter {
			left: 0;
			text-align: center;
			width: 100%; }
		&__prev {
			left: 50%;
			margin-left: -100px;
			z-index: 1000; }
		&__next {
			right: 50%;
			margin-right: -100px;
			z-index: 1000; } }

	.char-slider {
		&__card {
			margin-bottom: 100px;
			margin-top: 30px; }
		&__next {
			bottom: 20px;
			right: 50%;
			margin-right: -100px; }
		&__prev {
			bottom: 20px;
			left: 50%;
			margin-left: -100px; } }

	.char-slider-wrap {
		.slick-counter {
			bottom: 30px; } }

	.form-block {
		&__btn {
			width: 100%;
			display: block;
			padding: 12px 0; } }

	.addition-card {
		margin-bottom: 30px;
		&__text {
			height: 80px; } }

	.map {
		display: none; }

	.contact {
		padding: 40px 0;
		background-color: #ebe9e9; }

	.contact-form {
		&__input {
			background: transparent; }
		&__textarea {
			background: transparent; }
		&__btn {
			width: 100%;
			display: block; } }

	.footer-menu {
		&__list {
			flex-wrap: wrap; }
		&__link {
			margin-bottom: 15px;
			display: block;
			justify-content: center; }
		&__item {
			margin-right: 60px;
			text-align: center; } } }

@media (max-width: 991px) {
	.mobile-info-menu {
		&__item {
			margin-left: 40px; } }

	.gallery-block {
		&__item {
			margin-right: 20px; } }

	.stock {
		margin: 0; }

	.use-card {
		margin-bottom: 30px;
		&__text {
			padding: 0; } }

	.use {
		padding: 60px 0 40px 0; }

	.win-card {
		&__heading {
			font-size: 16px; }
		&__text {
			height: 120px; } } }

@media (max-width: 767px) {
	.color-card {
		padding: 0 40px; }

	.win-card {
		margin: 0 40px;
		margin-bottom: 30px;
		&__text {
			height: auto; } }

	.remodal-content {
		&__info {
			display: none; } }

	.mobile-header {
		&__logo-text {
			display: none; } }

	.mobile-info-menu {
		display: none; }

	.mobile-header {
		&__btn {
			margin: 0;
			margin-left: auto;
			font-size: 10px;
			padding: 5px 10px;
			width: 130px !important; } }

	.mobile-info-menu-2 {
		display: flex;
		.mobile-info-menu__item {
			margin: 0; } }

	.mobile-menu__list {
		display: none; }

	.mobile-menu__nav {
		display: flex;
		justify-content: space-between; }

	.mobile-menu__tel {
		font-size: 14px;
		display: inline-block;
		color: #000000;
		img {
			margin-right: 10px; } }

	.main-slider {
		&__content-wrap {
			width: 100%; } }

	.main-slider {
		&__title {
			font-size: 19px;
			line-height: 26px;
			margin-bottom: 15px; }
		&__text {
			font-size: 12px;
			line-height: 18px; }
		&__item {
			padding: 60px 30px 280px 30px; }
		&_first {
			background: url('../img/mobile-main-bg.png') no-repeat center / cover; }
		.slick-dots {
			bottom: 20px; }
		&__arrow {
			height: 25px;
			width: 25px;
			padding: 0;
			padding-left: 6px;
			display: flex;
			align-items: center;
			img {
				height: 8px; } } }

	.section {
		padding: 50px 0;
		&__heading {
			font-size: 19px; } }

	.gallery-block {
		&__text {
			font-size: 13px;
			line-height: 23px; }
		&__description {
			padding-top: 80px; }
		.slick-counter {
			bottom: 340px; }
		&__next {
			bottom: 338px;
			margin-right: -60px; }
		&__prev {
			bottom: 338px;
			margin-left: -60px; } }

	.about {
		&__heading {
			font-size: 19px; }
		&__text {
			font-size: 13px;
			line-height: 22px; } }

	.char-list {
		&__item {
			font-size: 12px; } }

	.char-slider {
		&__subheading {
			font-size: 12px; }
		&__prev {
			margin-left: -60px;
			bottom: 26px; }
		&__next {
			margin-right: -60px;
			bottom: 26px; } }

	.slick-counter {
		color: #adadad;
		font-size: 14px; }

	.stock {
		&__heading {
			font-size: 19px;
			margin-bottom: 20px; } }

	.char-slider-wrap {
		.slick-counter {
			margin-left: -20px; } }

	.use-card {
		&__image {
			img {
				margin: auto; } } }

	.install-slider {
		&__text {
			height: auto; }
		&__item {
			padding: 0 40px; }
		&__prev {
			left: 0; }
		&__next {
			right: 0; } }

	.addition-card {
		margin-left: 40px;
		margin-right: 40px; }

	.install {
		padding: 50px 0 90px 0; }

	.conf-hidden {
		display: none; }

	.conf-mobile {
		display: flex;
		margin-bottom: 15px; }

	.footer-menu {
		&__list {
			margin-top: 40px;
			flex-wrap: wrap; }
		&__item {
			text-align: left; } }

	.footer {
		position: relative;
		&__btn {
			position: absolute;
			font-size: 10px;
			top: -126px;
			right: 15px; } } }

@media (max-width: 575px) {
	.remodal {
		width: 100%; }

	.char-list__item {
		width: 100%; }

	.remodal-form {
		padding: 30px 20px; }

	.main-slider {
		&__text {
			padding: 0;
			margin-bottom: 30px; } }

	.gallery-block {
		&__list {
			display: block;
			text-align: center; }
		&__item {
			margin: 0;
			margin-bottom: 5px; }
		.slick-counter {
			bottom: 415px; }
		&__next {
			bottom: 412px; }
		&__prev {
			bottom: 412px; } }

	.logo-block {
		&__image {
			img {
				width: 100%;
				height: auto; } }
		&__text {
			font-size: 19px;
			margin-top: 15px; } }

	.char-slider {
		&__card {
			padding: 10px; }
		&__photo {
			img {
				width: 100%;
				height: auto; } }
		.char-list__item {
			width: 100%;
			font-size: 11px; }
		&__btn {
			margin: 0; }
		&__card-heading {
			margin: 0;
			margin-bottom: 10px; }
		&__next {
			bottom: 50px; }
		&__prev {
			bottom: 50px; }
		&__image {
			top: 10px;
			right: 10px;
			width: 45px;
			height: auto; } }

	.char-slider-2 {
		.char-list__item {
			width: 100%;
			font-size: 11px; } }

	.char-slider-wrap {
		.slick-counter {
			bottom: 52px;
			margin-left: -22px; } }

	.contact-block {
		display: block;
		margin: 0;
		margin-bottom: 15px;
		&__item {
			margin: 0;
			margin-bottom: 15px; } }

	.contact-form {
		&__textarea {
			height: 120px;
			border: none;
			padding: 0; } }

	.footer {
		padding: 40px 0;
		&__btn {
			top: -154px; } }

	.footer-menu {
		&__item {
			margin-right: 30px; } }

	.win-card {
		&__heading {
			font-size: 14px; }
		&__text {
			font-size: 12px; } }

	.use-card {
		&__text {
			font-size: 15px; } }

	.install-slider {
		&__text {
			font-size: 12px; } }

	.mobile-menu {
		margin-top: 20px; }

	.mobile-info-menu-2 {
		margin-top: 20px; }

	.addition-card {
		&__text {
			height: auto; }
		&__image {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			img {
				width: 100%;
				height: auto; } } } }
