#loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(43, 46, 56, 0.9);
	overflow: hidden;
	display: none;
}

#loader img {
	position: absolute;
	width: 50px;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	top: 50%;
	margin-top: -25px;
}

#overlay {
	display: none;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(43, 46, 56, 0.9);
	left: 0px;
	top: 0px;
	cursor: pointer;
}

#thx {
	background: #fff;
	position: absolute;
	left: 50%;
	margin-left: -170px;
	top: 50%;
	padding: 30px;
	margin-top: -20px;
}