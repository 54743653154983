@import "parts/mail.css";
@import "parts/remodal.css";
@import "parts/remodal-default-theme.css";
@import "parts/bootstrap-grid.css";
@import "parts/slick.css";

body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0; }

*:focus {
    outline: none; }

.list {
    list-style: none;
    padding: 0;
    margin: 0; }

.clearfix::after {
    content: "";
    clear: both;
    display: block; }

.section {
    padding: 70px 0;
    &_gray {
        background-color: #e7e7e7; }
    &_neon {
        background-image: linear-gradient(-240deg, #7c1742 0%, #332268 100%); }
    &_purple {
        background-color: #3f2162; }
    &_gray-gradient {
        background-image: linear-gradient(to top, #b9b9b9 0%, #d8d8d8 100%); }
    &__heading {
        color: #2a2a2a;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 1.5px;
        margin-bottom: 40px;
        text-align: center;
        &_white {
            color: #ffffff; } } }

a {
    text-decoration: none; }

h1, h2, h5, p, ul {
    margin: 0;
    padding: 0; }

.btn {
    display: inline-block;
    width: 210px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0.75px;
    padding: 15px 0;
    border-radius: 5px;
    border: none;
    &_red {
        background-image: linear-gradient(-45deg, #a50128 0%, #b92c30 100%); }
    &_transparent {
        border: 1px solid #ffffff; }
    &_black {
        background-color: #000000;
        color: #ffffff; }
    &_orange {
        background-color: #a95335; } }


.menu-colum {
    position: relative;
    z-index: 5;
    float: left;
    width: 400px;
    background-color: #fff;
    padding: 60px 40px 60px 115px;
    &__logo-block {
        margin-bottom: 210px; }
    &__logo {
        margin-bottom: 15px; }
    &__logo-text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 0.7px;
        display: block; }
    &__list {
        margin-bottom: 260px; }
    &__link {
        display: block;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.7px;
        color: #000000; }
    &__small-text {
        font-size: 14px;
        font-weight: 500;
        margin-right: 5px; }
    &__icon {
        margin-right: 5px; } }

.main-slider-wrap {
    z-index: 4;
    position: relative;
    &__btn {
        position: absolute;
        top: 40px;
        right: 60px; } }

.main-slider {
    position: relative;
    &_first {
        background: url('../img/neon-bg.png') no-repeat center / cover; }
    &__content-wrap {
        width: 740px;
        color: #ffffff;
        padding-bottom: 320px; }
    &__item {
        padding-top: 340px;
        padding-left: 130px;
        flex: 1; }
    &__title {
        font-size: 40px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        font-weight: 900; }
    &__text {
        font-weight: 300;
        font-size: 16px;
        opacity: 0.9;
        line-height: 28px;
        letter-spacing: 0.8px;
        margin-bottom: 45px;
        padding-right: 180px; }
    &__arrow {
        z-index: 100;
        cursor: pointer;
        top: 50%;
        position: absolute;
        border: 2px solid #919191;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding-top: 8px;
        padding-left: 12px; }
    &__next {
        right: 45px; }
    &__prev {
        left: 440px; }
    & .slick-dots {
        display: flex;
        list-style: none;
        position: absolute;
        bottom: 70px;
        left: 58%;
        & .slick-active {
            background-color: #afafaf; }
        & li {
            z-index: 10;
            width: 26px;
            height: 6px;
            border: 1px solid #afafaf;
            border-radius: 40px;
            margin-right: 10px; }
        & button {
            display: none; } } }

.info-menu {
    position: absolute;
    top: 60px;
    left: 525px;
    &__list {
        list-style: none;
        display: flex; }
    &__item {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0.7px;
        margin-right: 70px; }
    &__icon {
        margin-right: 10px; }
    &__link {
        color: #ffffff; } }

.gallery-block {
    position: relative;
    &__slider-wrap {
        display: none; }
    &__slider {
        &_active {
            display: block !important; } }
    &__list {
        list-style: none;
        display: flex;
        justify-content: space-between; }
    &__item {
        cursor: pointer;
        padding: 10px 15px;
        font-size: 14px;
        color: #2a2a2a;
        font-weight: 300;
        border: 1px solid #e7e7e7;
        transition: 0.3s;
        &_active {
            font-weight: 700;
            border: 1px solid #2a2a2a;
            border-radius: 5px; } }
    &__switch {
        margin-bottom: 40px; }
    &__flex-wrap {
        border-radius: 5px;
        display: flex; }
    &__description {
        padding: 40px 80px;
        background-color: #ffffff; }
    &__text {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.8; }
    &__next {
        right: 160px;
        top: inherit;
        bottom: 35px; }
    &__prev {
        top: inherit;
        left: 960px;
        bottom: 35px; }
    & .slick-counter {
        bottom: 40px;
        right: 225px;
        z-index: 100; } }

.slick-counter {
    position: absolute;
    color: #545454;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.9px; }



.logo-block {
    text-align: center;
    &__text {
        color: #2a2a2a;
        margin-top: 30px;
        font-size: 30px;
        letter-spacing: 1.5px;
        font-weight: 700; } }

.about {
    color: #ffffff;
    &__heading {
        font-size: 30px;
        margin-bottom: 45px;
        font-weight: 700; }
    &__subheading {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px; }
    &__text {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 24px; }
    &__image {
        img {
            max-width: 100%;
            height: auto; } }
    &__image-block {
        margin-bottom: 30px;
        &_no-mrg {
            margin: 0; } } }

.stock {
    margin-bottom: 90px;
    &__heading {
        color: #2a2a2a;
        font-size: 25px;
        margin-bottom: 30px; } }

.char-slider {
    position: relative;
    &__subheading {
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 18px; }
    &__card {
        position: relative;
        margin-top: 40px;
        border: 1px solid #c8c8c8;
        border-radius: 5px;
        padding: 35px 70px;
        & .char-list {
            height: 130px; } }
    &__card-heading {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.8px;
        margin-bottom: 10px; }
    &__card-subheading {
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 10px; }
    &__text {
        font-size: 13px;
        margin-bottom: 35px; }
    &__icon {
        position: relative;
        margin-bottom: -20px;
        z-index: 30; }
    &__photo {
        text-align: center;
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        height: 190px;
        img {
            height: 100%;
            width: auto;
            margin: auto; } }
    &__colors {
        display: flex; }
    &__round {
        width: 25px !important;
        height: 25px;
        margin-right: 15px;
        border-radius: 50%; }
    &__white {
        background-color: #ffffff;
        border: 1px solid #000000; }
    &__red {
        background-color: #e41b1b; }
    &__green {
        background-color: #63a558; }
    &__blue {
        background-color: #4761b4; }
    &__yellow {
        background-color: #d8e236; }
    &__orange {
        background-color: #c8a026; }
    &__purple {
        background-color: #ae199b; }
    &__btn {
        margin: auto;
        margin-top: 70px;
        width: 170px;
        padding: 10px;
        display: block; }
    &__next {
        top: inherit;
        bottom: -70px;
        right: 220px; }
    &__prev {
        top: inherit;
        bottom: -70px;
        left: 220px; }
    &__image {
        top: 40px;
        right: 70px;
        position: absolute;
        z-index: 1500000; } }

.char-slider-wrap {
    & .slick-counter {
        bottom: -60px;
        left: 50%;
        margin-left: -25px; } }

.char-list {
    &__item {
        padding-left: 25px;
        position: relative;
        width: 400px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 10px;
        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border: 1px solid #434343;
            border-radius: 50%;
            left: 0;
            top: 3px; } } }

.colors {
    padding: 70px 0 30px 0; }

.color-card {
    margin-bottom: 40px;
    &__name {
        margin-top: -4px;
        padding: 35px 0;
        border: 1px solid #bfbfbf;
        text-align: center;
        width: 100%;
        font-weight: 700; }
    &__image img {
        width: 100%;
        height: auto; } }

.use {
    padding: 80px 0 0 0;
    &__heading {
        margin-bottom: 60px; } }

.use-card {
    margin-bottom: 80px;
    text-align: center;
    &__image {
        margin-bottom: 35px; }
    &__text {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.89px;
        padding: 0 70px; } }

.win {
    padding: 70px 0 20px 0; }

.win-card {
    color: #ffffff;
    padding: 30px 30px;
    border: 1px solid #ffffff;
    margin-bottom: 50px;
    &__number {
        font-size: 36px;
        font-weight: 400;
        letter-spacing: 1.8px;
        margin-right: 10px; }
    &__heading {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        letter-spacing: 0.8px;
        font-weight: 700; }
    &__text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.7px;
        height: 80px; } }

.consultation {
    &__heading {
        margin-bottom: 10px; }
    &__subheading {
        font-size: 14px;
        text-align: center; } }

.form-block {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    &__btn {
        padding: 12px 0;
        width: 200px; }
    &__input {
        padding: 0 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        width: 200px; } }

.conf {
    margin-top: 20px;
    &__input {
        display: none;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-color: #000; }
    &__text {
        display: block;
        font-size: 12px;
        color: #7c7c7c; }
    &__link {
        text-decoration: underline;
        color: #7c7c7c; } }

.checkbox-label {
    float: left;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 5px;
    margin-right: 10px;
    &_white {
        border: 1px solid #fff; } }

.checkbox-label:before {
    position: absolute;
    content: '✓';
    width: 20px;
    height: 20px;
    display: none;
    text-align: center; }

.conf__input:checked + .checkbox-label:before {
    display: block; }

.install {
    padding: 70px 0 100px 0; }

.install-slider {
    position: relative;
    text-align: center;
    &__item {
        padding: 0 15px; }
    &__image {
        margin-bottom: -80px;
        img {
            margin: auto; } }
    &__card {
        padding: 100px 10px 30px 10px;
        border: 1px solid #ffffff;
        color: #ffffff; }
    &__heading {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px; }
    &__text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.7px; }
    &__next {
        text-align: left;
        top: 50%;
        right: -60px; }
    &__prev {
        text-align: left;
        top: 50%;
        left: -60px; }
    & .slick-dots {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        list-style: none;
        position: absolute;
        bottom: -50px;
        left: 0;
        & .slick-active {
            background-color: #afafaf; }
        & li {
            z-index: 10;
            width: 26px;
            height: 6px;
            border: 1px solid #afafaf;
            border-radius: 40px;
            margin-right: 10px; }
        & button {
            display: none; } } }

.addition {
    padding: 70px 0 20px 0; }

.addition-card {
    margin-bottom: 50px;
    padding: 35px 50px;
    border: 1px solid #ececec;
    text-align: center;
    &__image {
        margin-bottom: 10px;
        height: 120px;
        img {
            height: 100%;
            width: auto;
            margin: auto; } }
    &__text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 25px; }
    &__btn {
        padding: 8px 0;
        width: 100%; } }

.map-block {
    position: relative;
    img {
        max-width: 100%;
        height: auto; } }

.point-info {
    display: none; }

.point {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    &:before {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #000000;
        border: 5px solid #d5d5d5;
        left: -31px;
        top: -4px; }
    &__triangle {
        top: 12px;
        left: -25px;
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 25px 15px 0;
        border-color: transparent #000000 transparent transparent; } }

.spb {
    top: 214px;
    left: 239px; }

.arkhangelsk {
    top: 256px;
    left: 391px; }

.moscow {
    top: 308px;
    left: 240px; }

.novgorod {
    top: 357px;
    left: 277px; }

.semferopol {
    top: 379px;
    left: 47px; }

.perm {
    top: 383px;
    left: 429px; }

.rostov {
    top: 426px;
    left: 39px;
    &:before {
        left: 60px; }
    & .point-info {
        left: 109px; } }

.kasan {
    top: 412px;
    left: 319px; }

.volgograd {
    top: 434px;
    left: 172px; }

.ufa {
    top: 446px;
    left: 367px; }

.ecaterenburg {
    top: 419px;
    left: 440px; }

.krasnodar {
    top: 462px;
    left: 98px; }

.sochi {
    top: 488px;
    left: 109px; }

.astrahan {
    top: 488px;
    left: 187px; }

.orenburg {
    top: 487px;
    left: 308px; }

.cheljabinsk {
    top: 468px;
    left: 417px; }

.omsk {
    top: 513px;
    left: 517px; }

.novosibirsk {
    top: 577px;
    left: 617px; }

.krasnojarsk {
    top: 538px;
    right: 497px; }

.irkutsk {
    bottom: 104px;
    right: 421px; }

.chita {
    bottom: 125px;
    right: 353px; }

.habarovsk {
    bottom: 139px;
    right: 66px;
    & .point-info {
        left: inherit;
        right: 144px; }
    & .point__triangle {
        left: inherit;
        right: -25px;
        transform: rotate(180deg); } }

.vladivostok {
    bottom: 50px;
    right: 18px;
    & .point-info {
        left: inherit;
        right: 164px; }
    & .point__triangle {
        left: inherit;
        right: -25px;
        transform: rotate(180deg); } }

.magadan {
    top: 320px;
    right: 84px;
    & .point-info {
        left: inherit;
        right: 124px; }
    & .point__triangle {
        left: inherit;
        right: -25px;
        transform: rotate(180deg); } }

.point-info {
    display: none;
    z-index: 500;
    position: absolute;
    top: -20px;
    left: 30px;
    width: 325px;
    padding: 30px 30px 10px 30px;
    background-color: #000000;
    color: #ffffff;
    &__heading {
        font-size: 16px;
        margin-bottom: 20px; }
    &__subheading {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px; }
    &__text {
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 1.6; } }

.active {
    &:before {
        background-color: #c30000;
        box-shadow: 2px 4px 15px rgba(8, 8, 8, 0.3); } }

.contact-block {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    &__text {
        color: #000000; }
    &__icon {
        margin-right: 10px; }
    &__item {
        display: flex;
        align-items: center; } }

.follow {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &__text {
        font-size: 12px;
        color: #2c2c2c; }
    &__icon {
        margin-left: 15px; } }

.contact-form {
    &__input {
        font-size: 12px;
        border: none;
        padding: 5px 0;
        border-bottom: 1px solid #7c7c7c;
        margin-bottom: 20px;
        display: block;
        width: 100%; }
    .conf {
        padding-right: 40px; }
    &__textarea {
        width: 100%;
        border: none;
        border-left: 1px solid #7c7c7c;
        padding: 0 30px;
        height: 306px; }
    &__btn {
        border-radius: 0;
        margin-top: -10px;
        padding: 5px 0;
        width: 100%;
        font-size: 14px;
        padding: 8px 0; } }

.contact {
    position: relative;
    overflow: hidden;
    padding: 70px 0 0 0;
    &__photo {
        margin-top: -70px;
        margin-left: -30px;
        width: auto; }
    &__heading {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.2px;
        margin-bottom: 30px;
        text-align: left; } }

.footer {
    padding: 60px 0;
    background-color: #000000;
    &__logo {
        display: flex;
        align-items: center;
        height: 20px;
        img {
            height: 100%;
            width: auto; } } }

.footer-menu {
    width: 100%;
    display: flex;
    align-items: center;
    &__list {
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: space-between; }
    &__link {
        color: #d4d4d4;
        font-size: 14px; } }

.remodal {
    padding: 0;
    max-width: 940px; }

.remodal-content {
    display: flex;
    &__info {
        background-color: #eaeaea;
        padding: 40px;
        flex-shrink: 0; }
    &__image {
        margin-bottom: 35px;
        img {
            width: 100%;
            height: auto; } }
    &__heading {
        background-image: linear-gradient(-240deg, #7c1742 0%, #332268 100%);
        background-clip: text;
        margin-bottom: 25px;
        font-size: 16px;
        font-weight: 700; }
    .char-list__item {
        text-align: left; } }

.remodal-form {
    color: #ffffff;
    text-align: center;
    background-image: linear-gradient(-240deg, #7c1742 0%, #332268 100%);
    padding: 40px;
    &__heading {
        font-size: 15px;
        margin-bottom: 20px; }
    &__text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 25px; }
    &__input {
        padding: 14px;
        border-radius: 5px;
        border: none;
        margin-bottom: 15px;
        display: block;
        font-size: 12px;
        width: 100%; }
    &__btn {
        width: 100%;
        margin-top: 25px; }
    .conf__text, .conf__link {
        color: #ffffff;
        text-align: left; } }

.remodal-close {
    color: #ffffff; }

.mobile-header {
    display: none;
    padding: 40px 0 0 0;
    &__logo-text {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px; }
    &__btn {
        color: #000000;
        border: 1px solid #000000;
        display: block;
        margin-left: auto;
        width: 200px !important;
        margin-top: 25px; }
    .container {
        position: relative; } }

.mobile-info-menu {
    display: flex;
    list-style: none;
    justify-content: flex-end;
    &__item {
        color: #000000;
        margin-left: 60px;
        font-size: 14px; }
    &__icon {
        margin-right: 5px; }
    &__link {
        font-size: 14px;
        color: #000000; } }

.mobile-menu {
    display: none;
    margin-top: 40px;
    padding: 25px 0;
    background-color: #ebe9e9;
    &__list {
        padding: 30px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none; }
    &__link {
        display: inline-block;
        margin-right: 30px;
        font-size: 14px;
        color: #000000;
        margin-bottom: 30px; } }

.mobile-info-menu-2 {
    margin-top: 30px;
    display: none;
    justify-content: space-between; }

.mobile-menu__nav {
    display: none; }

.mobile-slider, .mobile-slider-arrow, .mobile-slider-colors {
    & .slick-dots {
        display: flex;
        list-style: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        & .slick-active {
            background-color: #afafaf; }
        & li {
            z-index: 10;
            width: 26px;
            height: 6px;
            border: 1px solid #afafaf;
            border-radius: 40px;
            margin-right: 10px; }
        & button {
            display: none; } } }

.mobile-slider-arrow, .mobile-slider-colors {
    position: relative;
    &__next {
        top: 25%;
        right: 10px; }
    &__prev {
        top: 25%;
        left: 10px !important; } }

.conf-mobile {
    display: none; }

.burger-menu {
    z-index: 100000;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ebe9e9;
    width: 100%;
    &__list {
        padding: 35px;
        list-style: none;
        text-align: center; }
    &__link {
        font-size: 14px;
        border-bottom: 1px solid #cfcdcd;
        padding: 10px 0;
        color: #000000;
        display: block; } }

@import "parts/media.sass";
